import React, { useEffect, useRef } from 'react'
import style from './style.module.scss'
import { initialize, fetchMoreScenes, selectState } from './redux'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import {
  RouteComponentProps
} from 'react-router-dom'
import SceneGrid from 'features/SceneGrid'
import InfoRow from './InfoRow'

const mapDispatch = { initialize, fetchMoreScenes }
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps<{id: string}>

function UserPage (props: Props) {
  const { id } = props.match.params
  useEffect(() => {
    props.initialize(Number(id))
  }, [props, id])
  const {
    initialized,
    initializing,
    fetchingScenes,
    scenes,
    user,
    total
  } = useSelector(selectState)

  const gridRef = useRef<HTMLDivElement>(null)

  // incremental scene loading
  useEffect(() => {
    const trackScrolling = () => {
      if (initializing || fetchingScenes || scenes.length === total) {
        return
      }
      if (!gridRef?.current) {
        return
      }
      if (gridRef.current.getBoundingClientRect().bottom < window.innerHeight + 100) {
        props.fetchMoreScenes()
      }
    }

    document.addEventListener('scroll', trackScrolling)
    return () => document.removeEventListener('scroll', trackScrolling)
  }, [props, initializing, fetchingScenes, scenes.length, total])

  if (!initialized || initializing) {
    return <div></div>
  }

  return (
    <div className={style.frame}>
      <div>
        {user && (
          <InfoRow user={user} totalScenes={total} />
        )}
      </div>
      <div ref={gridRef}>
        <SceneGrid scenes={scenes} onGoodClick={() => {}} onMylistClick={() => {}} />
      </div>
    </div>
  )
}

export default connector(UserPage)
