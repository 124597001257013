import { v4 as uuid } from 'uuid'
import isUUID from 'uuid-validate'

declare global {
  interface Window {
    receiveMessageFromUnity: (message: string) => void
    sendMessageToUnity?: (message: string) => void
    Unity?: Unity
  }
}

/**
 * Gateway Object to send message to Unity on Android devices.
 */
export type Unity = {
  sendMessageToUnity: (message: string) => void
}

enum MessageType {
  RESPONSE = 'RESPONSE',
  REQUEST = 'REQUEST'
}

export enum Status {
  OK = 'OK',
  ERROR = 'ERROR'
}

/**
 * Request object passed between web and Unity.
 */
export class Request {
  outGoing: boolean
  id: string
  context: string
  payload: any
  /**
   * Registered callback on OK response.
   */
  private givenOnOk: (payload: any) => void
  /**
   * Registered callback on ERROR response.
   */
  private givenOnError: (message: string) => void

  constructor (
    outGoing: boolean,
    id: string,
    context: string,
    payload: any,
    onOk: (payload: any) => void,
    onError: (message: string) => void
  ) {
    this.outGoing = outGoing
    this.id = id
    this.context = context
    this.payload = payload
    this.givenOnOk = onOk
    this.givenOnError = onError
  }

  /**
   * Use this to respond with OK.
   */
  respondOk (payload: any) {
    this.givenOnOk(payload)
  }

  /**
   * Use this to respond with ERROR
   */
  respondError (message: string) {
    this.givenOnError(message)
  }
}

/**
 * Tunnel class connects this app and Unity.
 */
export class Tunnel {
  /**
   * Request and id map handled by a tunnel.
   */
  private requests = new Map<string, Request>()
  /**
   * ID generated setTimeout for requests.
   */
  private requestTimeoutIds = new Map<string, number>()
  /**
   * Callback registered on creation of a tunnel.
   */
  private onRequest: (req: Request) => void
  /**
   * Timeout millisec. Default is 10s.
   * Req / Res cannot go through the tunnel if exceeds this time threshold.
   */
  private timeout = 10 * 1000

  /**
   * @param onRequest Callback for receiving requests from Unity.
   */
  constructor (onRequest: (req: Request) => void) {
    this.onRequest = onRequest
  }

  /**
   * Sets timeout millisec
   */
  setTimetout (timeout: number) {
    this.timeout = timeout
  }

  /**
   * Opens a tunnel to communicate with Unity.
   */
  open () {
    window.receiveMessageFromUnity = (message: string) => {
      this.receiveMessage(message)
    }
  }

  /**
   * Closes the tunnel.
   */
  close () {
    window.receiveMessageFromUnity = () => {}
  }

  /**
   * Receives message from Unity and parse / validate.
   * Handles both requests from Unity and responses from Web.
   * @param stringifiedMessage stringified JSON message
   */
  private receiveMessage (stringifiedMessage: string) {
    let parsedMessage: any = null
    try {
      parsedMessage = JSON.parse(stringifiedMessage)
    } catch (e) {
      console.error(`Failed to parse message JSON. ${stringifiedMessage}`)
    }
    if (!parsedMessage) {
      return
    }
    const {
      id,
      type,
      payload
    } = parsedMessage
    if (!id) {
      console.error('id is not given')
      return
    }
    if (!isUUID(id)) {
      console.error(`id is not UUID format. ${id}`)
      return
    }
    if (!type) {
      console.error('type is not given.')
      return
    }
    if (!payload) {
      console.error('payload is not given.')
      return
    }
    if (type === MessageType.REQUEST) {
      const { context } = parsedMessage
      if (!context || (typeof context) !== 'string') {
        console.error('No context or invalid context.')
        return
      }
      this.receiveRequest(id, context, payload)
    } else if (type === MessageType.RESPONSE) {
      const { status } = parsedMessage
      if (!status || !Object.values(Status).includes(status)) {
        console.error('No status or invalid status.')
        return
      }
      this.receiveResponse(id, status, payload)
    } else {
      console.error(`Invalid message type. ${type}`)
    }
  }

  /**
   * Sedns a message to Unity.
   * Handles both requests from web and responses from Unity.
   * @param stringifiedMessage stringified JSON message
   */
  private sendMessage (stringifiedMessage: string) {
    if (window.Unity) {
      try {
        window.Unity.sendMessageToUnity(stringifiedMessage)
      } catch (e) {
        throw new Error('Error on calling window.Unity.sendMessageToUnity')
      }
    } else {
      throw new Error('window.Unity is not defined.')
    }
  }

  /**
   * Fires on received a request from Unity with valid message properties.
   * @param id ID of a request
   * @param context Context
   * @param payload
   */
  private receiveRequest (id: string, context: string, payload: any) {
    // If a request with the id already exists, ignore the request.
    if (this.requests.get(id)) {
      return
    }
    const req = new Request(
      false,
      id,
      context,
      payload,
      (payload: any) => {
        clearTimeout(timeoutId)
        this.sendResponse(id, Status.OK, payload)
        this.requests.delete(id)
        this.requestTimeoutIds.delete(id)
      },
      (message: string) => {
        clearTimeout(timeoutId)
        this.sendResponse(id, Status.ERROR, { message })
        this.requests.delete(id)
        this.requestTimeoutIds.delete(id)
      }
    )
    const timeoutId = window.setTimeout(() => {
      const req = this.requests.get(id)
      if (req) {
        this.requests.delete(id)
        this.requestTimeoutIds.delete(id)
      }
    }, this.timeout)
    this.requests.set(id, req)
    this.requestTimeoutIds.set(id, timeoutId)
    this.onRequest(req)
  }

  /**
   * Fires on received a reponse from Unity with valid message properties.
   * @param id ID of a request
   * @param status
   * @param payload
   */
  private receiveResponse (id: string, status: Status, payload: any) {
    const req = this.requests.get(id)
    if (req) {
      if (status === Status.OK) {
        req.respondOk(payload)
      } else {
        req.respondError(payload.message)
      }
    }
  }

  /**
   * Sends request to Unity.
   * @param context
   * @param payload
   */
  sendRequest (
    context: string,
    payload: any,
    onRespond: (payload: any) => void,
    onError: (message: string) => void
  ): Request {
    const id = uuid()

    const timeoutId = window.setTimeout(() => {
      const req = this.requests.get(id)
      if (req) {
        req.respondError('Timeout')
        this.requests.delete(id)
        this.requestTimeoutIds.delete(id)
      }
    }, this.timeout)

    const req = new Request(
      true,
      id,
      context,
      payload,
      (payload: any) => {
        clearTimeout(timeoutId)
        this.requests.delete(id)
        this.requestTimeoutIds.delete(id)
        onRespond(payload)
      }, (message: string) => {
        clearTimeout(timeoutId)
        this.requests.delete(id)
        this.requestTimeoutIds.delete(id)
        onError(message)
      })

    this.sendMessage(JSON.stringify({
      id,
      type: MessageType.REQUEST,
      context,
      payload
    }))
    this.requests.set(id, req)
    this.requestTimeoutIds.set(id, timeoutId)
    return req
  }

  /**
   * Sends response to Unity.
   */
  private sendResponse (id: string, status: Status, payload: any) {
    const req = this.requests.get(id)
    if (req) {
      this.sendMessage(JSON.stringify({
        id,
        type: MessageType.RESPONSE,
        status,
        payload
      }))
    }
  }
}
