import React from 'react'
import classnames from 'classnames'
import style from './style.module.scss'

type Props = {
  text: string,
  important: boolean,
}

export default React.memo(function Tag ({ text, important }: Props) {
  return (
    <div
      className={classnames({
        [style.frame]: true,
        [style.important]: !!important
      })}
    >
      {`#${text}`}
    </div>
  )
})
