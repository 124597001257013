import React from 'react'
import classnames from 'classnames'
import styles from './style.module.scss'

function Section () {
  return (
    <div className={styles.section}>
      <div className={classnames(styles.block, styles.image)} />
      <div className={styles.meta}>
        <div className={classnames(styles.block, styles.header)} />
        <div className={styles.description}>
          <div className={classnames(styles.block, styles.sentence)} />
          <div className={classnames(styles.block, styles.sentence)} />
          <div className={classnames(styles.block, styles.sentence)} />
          <div className={classnames(styles.block, styles.sentence)} />
          <div className={classnames(styles.block, styles.sentence)} />
          <div className={classnames(styles.block, styles.sentence)} />
          <div className={classnames(styles.block, styles.sentence)} />
        </div>
      </div>
    </div>
  )
}

export default function LoadingPlaceholder () {
  return (
    <div className={styles.frame}>
      <Section />
      <Section />
    </div>
  )
}
