import React, { useCallback, useEffect } from 'react'
import style from './style.module.scss'
import { loadScene, selectState } from './redux'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import {
  RouteComponentProps, Link
} from 'react-router-dom'
import TagComponent from 'features/Tag'
import moment from 'moment'
import InfoRow from './InfoRow'
import Billboard from './Billboard'
import { playScene, shareScene, openExternalPage } from 'utils/tunnel/redux'
import Scene from 'utils/api/models/Scene'
import Tag from 'utils/api/models/Tag'
import { FEATURED_TAGS } from 'app/constants'
import StatsRow from './StasRow'
import Markdown from 'features/Markdown'
import LicenseBox from './LicenseBox'
import SceneGrid from 'features/SceneGrid'

const mapDispatch = {
  loadScene,
  openExternalPage,
  playScene,
  shareScene
}

const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps<{id: string}>

const renderTags = (tags: Array<Tag>) => {
  return (
    <div className={style.tagContainer}>
      {tags.map(t => {
        return (
          <Link key={t.id} to={`/search?q=${encodeURIComponent(`#${t.text}`)}`}>
            <TagComponent
              key={t.id}
              text={t.text}
              important={FEATURED_TAGS.includes(t.text)}
            />
          </Link>
        )
      })}
    </div>
  )
}

const ScenePage = ({
  loadScene,
  match,
  openExternalPage,
  playScene,
  shareScene
}: Props) => {
  const { id } = match.params
  const { scene, licenses, recommendedScenes } = useSelector(selectState)

  useEffect(() => {
    loadScene(id)
  }, [id, loadScene])

  const onShareClick = useCallback(() => {
    if (scene) {
      shareScene({
        id: scene.id,
        title: scene.title
      })
    }
  }, [scene, shareScene])

  const onBillboardClick = useCallback(() => {
    playScene(id)
  }, [id, playScene])

  if (!scene) {
    return <div></div>
  }

  const onGoodClick = () => {}
  const onMylistClick = () => {}
  const onClickInMarkDown = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const aTag = e.target as HTMLAnchorElement
    openExternalPage(aTag.href)
  }

  const renderSceneDetail = (
    scene: Scene
  ) => {
    const { user } = scene
    return (
      <div>
        <div className={style.userSection}>
          <InfoRow
            user={user}
            date={moment(scene.publishedAt).format('YYYY/MM/DD')}
            profileImageUrl={user.profileImageUrl || null}
          />
        </div>
        <div className={style.description}>
          {scene.description ? (
            <Markdown source={scene.description} onClickLink={onClickInMarkDown}/>
          ) : 'This scene has no description.'}
        </div>
      </div>
    )
  }

  return (
    <div className={style.frame}>
      <Billboard
        thumbnailUrl={scene.thumbnail.url}
        onClick={onBillboardClick}
      />
      <div className={style.documentWrapper}>
        {renderTags(scene.tags)}
        <div className={style.title}>{scene.title}</div>
        <StatsRow
          goodCount={scene.goodCount}
          onShareClick={onShareClick}
        />
        {renderSceneDetail(scene)}
        {licenses.length > 0 && (
          <LicenseBox licenses={licenses.map(lines => lines.split(','))} />
        )}
      </div>
      {recommendedScenes.length > 0 && (
        <div className={style.recommended}>
          <div className={style.header}>Recommended scenes for you</div>
          <SceneGrid
            scenes={recommendedScenes}
            onGoodClick={onGoodClick}
            onMylistClick={onMylistClick}
          />
        </div>
      )}
    </div>
  )
}

export default connector(ScenePage)
