import React from 'react'
import style from './style.module.scss'
import GalleryIcon from '@material-ui/icons/AmpStories'
import { Link } from 'react-router-dom'
import QrIcon from 'icons/Qr'

type Props = {
  onCameraClick: () => void
}

/**
 * Floating bottom footer.
 */
export default React.memo(function Footer ({ onCameraClick }: Props) {
  const onQrClick = () => {
    onCameraClick && onCameraClick()
  }

  return (
    <div
      className={style.frame}
    >
      <div className={style.inner}>
        <Link to='/'>
          <div className={style.cell}>
            <div>
              <GalleryIcon/>
            </div>
            <div>GALLERY</div>
          </div>
        </Link>
        <div className={style.cell} onClick={onQrClick}>
          <div>
            <QrIcon/>
          </div>
          <div>CAMERA</div>
        </div>
      </div>
    </div>
  )
})
