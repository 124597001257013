import style from './style.module.scss'
import SceneGrid from 'features/SceneGrid'
import React, { useEffect, useRef } from 'react'
import { fetchScenes, fetchMoreScenes } from './redux'
import { ConnectedProps, connect } from 'react-redux'
import SceneListItem from 'utils/api/models/SceneListItem'
import { RouteComponentProps } from 'react-router-dom'
import { RootState } from 'app/store'
import { Location } from 'history'
import queryString from 'query-string'
import SceneLoadingPlaceholder from 'features/SceneLoadingPlaceholder'
import { changeText } from 'reduxComponents/SearchBox/redux'

const mapState = (state: RootState) => ({ ...state.sceneSearch })
const mapDispatch = { fetchScenes, fetchMoreScenes, changeText }
const connector = connect(mapState, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps

function parseQuery (location: Location) {
  let query = queryString.parse(location.search).q || ''
  if (Array.isArray(query)) {
    query = query[0]
  }
  return query
}

function SceneSearchPage ({
  location,
  scenes,
  total,
  loadingScenes,
  fetchScenes,
  fetchMoreScenes,
  initialLoadDone,
  changeText
}: Props) {
  const gridRef = useRef<HTMLDivElement>(null)

  const query = parseQuery(location)

  // initial scene loading
  useEffect(() => {
    fetchScenes(query)
    changeText(query)
  }, [changeText, fetchScenes, query])

  // incremental scene loading
  useEffect(() => {
    const trackScrolling = () => {
      if (loadingScenes || scenes.length === total) {
        return
      }
      if (!gridRef?.current) {
        return
      }
      if (gridRef.current.getBoundingClientRect().bottom < window.innerHeight + 10) {
        fetchMoreScenes(query)
      }
    }

    document.addEventListener('scroll', trackScrolling)
    return () => document.removeEventListener('scroll', trackScrolling)
  }, [fetchMoreScenes, loadingScenes, query, scenes.length, total])

  const onGoodClick = (scene: SceneListItem) => {
    console.log('Good clicked: ' + scene.title)
  }

  const onMylistClick = (scene: SceneListItem) => {
    console.log('My list clicked: ' + scene.title)
  }

  return (
    <div className={style.frame}>
      <div className={style.header}>
        Search&nbsp;
        {query || 'All'}
      </div>
      <div ref={gridRef}>
        <SceneGrid scenes={scenes} onGoodClick={onGoodClick} onMylistClick={onMylistClick} />
      </div>
      {loadingScenes && <SceneLoadingPlaceholder />}
      {initialLoadDone && scenes.length === 0 && (
        <div className={style.noScenesMessage}>
          <div className={style.message}>No scene is found.</div>
        </div>
      )}
    </div>
  )
}

export default connector(SceneSearchPage)
