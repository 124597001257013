import React from 'react'
import User from 'utils/api/models/User'
import style from './style.module.scss'
import AccountIcon, { Size } from 'features/AccountIcon'

type Props = {
  user: User
  totalScenes: number
}

export default function InfoRow ({
  user,
  totalScenes
}: Props) {
  return (
    <div className={style.frame}>
      <div>
        <AccountIcon
          size={Size.L}
          username={user.username}
          profileImageUrl={user.profileImageUrl || null}
        />
      </div>
      <div className={style.username}>{user.username}</div>
      <div className={style.totalScenes}>{`${totalScenes} scenes`}</div>
    </div>
  )
}
