import React, { useState, useCallback, useEffect } from 'react'
import style from './style.module.scss'
import { Link } from 'react-router-dom'
import logoUrl from 'images/logo_horizontal.png'
import IconButton from '@material-ui/core/IconButton'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import history from 'utils/history'
import SearchBox from 'reduxComponents/SearchBox'

/**
 * Floating bottom footer.
 */
export default React.memo(function Header () {
  const [showBack, setShowBack] = useState(false)
  const goBack = useCallback(() => {
    history.goBack()
  }, [])

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname === '/') {
        setShowBack(false)
      } else {
        setShowBack(true)
      }
    })
  }, [])

  return (
    <div
      className={style.frame}
    >
      <div className={style.inner}>
        <div className={style.backButton}>
          {showBack && (
            <IconButton onClick={goBack}>
              <ArrowLeftIcon />
            </IconButton>
          )}
        </div>
        <Link to='/'>
          <img src={logoUrl} alt="logo" />
        </Link>
        <SearchBox />
      </div>
    </div>
  )
})
