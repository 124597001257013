import React from 'react'
import style from './style.module.scss'

function SceneLoadingPlaceholder () {
  return (
    <div className={style.frame}>
      {Array(12)
        .fill(null)
        .map((_, i) => {
          return <div key={i} className={style.block} />
        })}
    </div>
  )
}

export default SceneLoadingPlaceholder
