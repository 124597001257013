import React from 'react'
import store from 'app/store'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { configureTunnel } from 'utils/tunnel/redux'
import history from 'utils/history'
import App from 'app/App'

// Opens communication tunnel to Unity backend.
configureTunnel(store)

function Root () {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>
  )
}

export default Root
