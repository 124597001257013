import React from 'react'
import ShareIcon from '@material-ui/icons/Share'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import IconButton from '@material-ui/core/IconButton'
import style from './style.module.scss'

type Props = {
  goodCount: number
  onShareClick: () => void
}

export default function StatsRow ({
  goodCount, onShareClick
}: Props) {
  return (
    <div className={style.frame}>
      <div className={style.stats}>
        <FavoriteBorderIcon className={style.favorite}/>
        {goodCount}
      </div>
      <div className={style.iconContainer}>
        <IconButton onClick={onShareClick}>
          <ShareIcon />
        </IconButton>
      </div>
    </div>
  )
}
