import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ApiClient from 'utils/api'
import SceneListItem from 'utils/api/models/SceneListItem'
import { API_BASE_PATH } from 'app/env'
import { REDUX_ACTION_TYPE_PREFIX } from 'app/constants'
import { RootState } from 'app/store'

/**
 * Number of scenes in a chunk to load.
 */
export const NUM_SCENES_OF_CHUNK = 48

const client = new ApiClient(API_BASE_PATH)
const sliceName = `${REDUX_ACTION_TYPE_PREFIX}/sceneSearch`

export const fetchScenes = createAsyncThunk(
  `${sliceName}/fetchScenes`,
  async (query: string) => {
    const res = await client.searchScenes(query, NUM_SCENES_OF_CHUNK)
    return res
  }
)

export const fetchMoreScenes = createAsyncThunk(
  `${sliceName}/fetchMoreScenes`,
  async (query: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const offset = state.sceneSearch.scenes.length
    const res = await client.searchScenes(query, NUM_SCENES_OF_CHUNK, offset)
    return res
  }
)

export const sceneSearchSlice = createSlice({
  name: sliceName,
  initialState: {
    scenes: [] as SceneListItem[],
    loadingScenes: false,
    initialLoadDone: false,
    total: 0
  },
  reducers: { },
  extraReducers: builder => {
    builder
    // fetchScenes
      .addCase(
        fetchScenes.pending,
        (state) => {
          state.loadingScenes = true
          state.initialLoadDone = false
        })
      .addCase(
        fetchScenes.rejected,
        (state) => {
          state.loadingScenes = false
        })
      .addCase(
        fetchScenes.fulfilled,
        (state, action) => {
          state.loadingScenes = false
          state.initialLoadDone = true
          state.scenes = action.payload.scenes
          state.total = action.payload.total
        })

    // fetchMoreScenes
      .addCase(
        fetchMoreScenes.pending,
        (state) => {
          state.loadingScenes = true
        })
      .addCase(
        fetchMoreScenes.rejected,
        (state) => {
          state.loadingScenes = false
        })
      .addCase(
        fetchMoreScenes.fulfilled,
        (state, action) => {
          state.loadingScenes = false
          state.scenes = state.scenes.concat(action.payload.scenes)
        })
  }
})

export default sceneSearchSlice.reducer
