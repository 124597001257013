import React, { useEffect, useCallback } from 'react'
import style from './style.module.scss'
import { openExternalPage } from 'utils/tunnel/redux'
import { initialize, selectState } from './redux'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import PickupSceneListComponent from './PickupSceneList'
import AdList from './AdList'
import Tag from 'features/Tag'
import Billboard from './Billboard'
import { Link } from 'react-router-dom'
import LoadingPlaceholder from './LoadingPlaceholder'
import history from 'utils/history'

const mapDispatch = { initialize, openExternalPage }
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector>

/**
 * Top Page. Holds Picked up scenes like Staff picks.
 */
function TopPage ({ initialize, openExternalPage }: Props) {
  /**
   * Opens ad in brower. Sends URL open request to Unity.
   */
  const onAdClick = useCallback((url: string) => {
    openExternalPage(url)
  }, [openExternalPage])

  const state = useSelector(selectState)
  const {
    initializing,
    initialized,
    pickupSceneLists,
    pickupSceneListMap,
    ads,
    featuredTags,
    staffPicksScenes
  } = state

  useEffect(() => {
    if (!initialized) {
      initialize()
    }
  }, [initialize, initialized])

  const onBillboardClick = useCallback((sceneId: string) => {
    history.push(`/scene/${sceneId}`)
  }, [])

  if (initializing || !initialized) {
    return (
      <LoadingPlaceholder />
    )
  }

  return (
    <div className={style.frame}>
      <Billboard scenes={staffPicksScenes} onClick={onBillboardClick}/>
      <AdList ads={ads} onAdClick={onAdClick}/>
      <div className={style.tagContainer}>
        <div>
          {featuredTags.filter(t => t.important).map(t => {
            return (
              <Link key={t.id} to={`/search?q=${encodeURIComponent('#' + t.text)}`}>
                <Tag text={t.text} important={t.important}/>
              </Link>
            )
          })}
        </div>
        <div>
          {featuredTags.filter(t => !t.important).map(t => {
            return (
              <Link key={t.id} to={`/search?q=${encodeURIComponent('#' + t.text)}`}>
                <Tag text={t.text} important={t.important}/>
              </Link>
            )
          })}
        </div>
      </div>
      {pickupSceneLists.map(list => {
        const scenes = pickupSceneListMap[list.id]
        if (scenes) {
          return (
            <PickupSceneListComponent
              key={list.id}
              list={list}
              scenes={scenes}
              viewAllPath={`/search?q=${window.encodeURIComponent(list.searchQuery)}`}
            />
          )
        } else {
          return <></>
        }
      })}
    </div>
  )
}

export default connector(TopPage)
