import React, { useState, SyntheticEvent } from 'react'
import style from './style.module.scss'
import classnames from 'classnames'
import LazyLoad from 'react-lazyload'
import SceneListItem from 'utils/api/models/SceneListItem'
import { Link } from 'react-router-dom'
import logoUrl from 'images/logo_horizontal.png'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import AccountIcon from 'features/AccountIcon'

interface SceneGridProps {
  scenes: SceneListItem[]
  onGoodClick: (scene: SceneListItem) => void
  onMylistClick: (scene: SceneListItem) => void
}

function SceneGrid ({ scenes, onGoodClick, onMylistClick }: SceneGridProps) {
  return (
    <div className={style.cardContainer}>
      {scenes.map(scene => {
        return (
          <Link key={scene.id} to={`/scene/${scene.id}`}>
            <SceneCard scene={scene} onGoodClick={onGoodClick} onMylistClick={onMylistClick} />
          </Link>
        )
      })}
    </div>
  )
}

interface SceneCardProps {
  scene: SceneListItem
  onGoodClick?: (scene: SceneListItem) => void
  onMylistClick?: (scene: SceneListItem) => void
}

function SceneCard ({ scene, onMylistClick = undefined, onGoodClick = undefined }: SceneCardProps) {
  const { title, user, mylisted, gooded, thumbnail } = scene

  // Whether to show thumbnail or not.
  // This is used for lazy loading control.
  const [thumbnailVisible, setThumbnailVisible] = useState(false)

  // Fires when thumbnail image is laoded.
  const handleThubmnailLoad = () => {
    setThumbnailVisible(true)
  }
  // Fires when failed to laod thumbnail image.
  const handleThubmnailLoadError = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.style.display = 'none'
  }
  // いいねポタンが押されたときのコールバック
  const handleGoodClick = (e: SyntheticEvent, scene: SceneListItem) => {
    e.stopPropagation()
    e.preventDefault()
    if (onGoodClick) onGoodClick(scene)
  }
  // マイリストに追加ポタンが押されたときのコールバック
  const handleMylistClick = (e: SyntheticEvent, scene: SceneListItem) => {
    e.stopPropagation()
    e.preventDefault()
    if (onMylistClick) onMylistClick(scene)
  }
  /**
   * 作者のページに飛ばすために必要 !!
   * イベントバブリングを阻止する
   */
  const handleUsernameClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div className={style.frame}>
      <div
        className={style.background}
        style={{
          backgroundImage: `url(${logoUrl})`
        }}
      />
      <LazyLoad height="100%">
        <img
          className={classnames(
            style.thumbnail,
            { [style.visible]: thumbnailVisible }
          )}
          alt="Scene thumbnail"
          src={thumbnail.url}
          onError={handleThubmnailLoadError}
          onLoad={handleThubmnailLoad}
        />
      </LazyLoad>
      <div className={style.shadowCover}>
        <div className={style.contentContainer}>
          <div className={style.title}>{title}</div>
          { user &&
            <>
              <div className={style.userIcon}>
                <Link to={`/user/${user.id}`}>
                  <AccountIcon
                    username={user.username}
                    profileImageUrl={user.profileImageUrl || ''}
                  />
                </Link>
              </div>
              <div className={style.username} onClick={handleUsernameClick}>
                <Link to={`/user/${user.id}`}>
                  <span>{user.username}</span>
                </Link>
              </div>
            </>
          }
          <div className={style.iconContainer}>
            <div
              className={classnames(
                style.iconButton,
                style.mylist,
                { [style.mylisted]: mylisted }
              )}
              onClick={e => handleMylistClick(e, scene)}
            >
              <PlaylistAddIcon />
            </div>
            <div
              className={classnames({
                [style.iconButton]: true,
                [style.good]: true,
                [style.gooded]: gooded
              })}
              onClick={e => handleGoodClick(e, scene)}
            >
              <FavoriteBorderIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SceneGrid
