import React from 'react'
import AccountIcon, { Size } from '../../../features/AccountIcon'
import { Link } from 'react-router-dom'
import User from 'utils/api/models/User'

import moment from 'moment'
import style from './style.module.scss'

type Props = {
  user: User
  profileImageUrl: string | null
  date?: string
}

const InfoRow = (props: Props) => {
  const user = props.user
  return (
    <div className={style.frame}>
      <div className={style.iconContainer}>
        <Link to={`/user/${user.id}`}>
          <AccountIcon
            username={user.username}
            size={Size.M}
            profileImageUrl={props.profileImageUrl }
          />
        </Link>
      </div>
      <div className={style.textContainer}>
        <div>
          <div className={style.usernmae}>
            <Link to={`/user/${user.id}`}>
              {user.username}
            </Link>
          </div>
        </div>
        <div className={style.date}>
          {moment(props.date).format('YYYY/MM/DD')}
        </div>
      </div>
    </div>
  )
}

export default InfoRow
