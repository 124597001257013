import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import AccountIcon from 'features/AccountIcon'
// import GoodIcon from '../../../../components/GoodIcon'
// import MylistIcon from '../../../../components/MylistIcon'
import style from './style.module.scss'
import PickupScene from 'models/PickupScene'
import PickupSceneList from 'utils/contentful/PickupSceneList'

type Props = {
  list: PickupSceneList
  scenes: PickupScene[]
  viewAllPath: string
}

/**
 * Pickup scene list for mobile with vertical rectangles.
 */
export default function PickupSceneListComponent ({
  list,
  scenes,
  viewAllPath
}: Props) {
  /*
  const onMylistClickInternal = (e, scene) => {
    e.preventDefault()
    onMylistClick && onMylistClick(scene)
  }
  const onGoodClickInternal = (e, scene) => {
    e.preventDefault()
    onGoodClick && onGoodClick(scene)
  }
  */
  return (
    <div className={style.frame}>
      <div className={style.header}>
        <Link to={viewAllPath}>
          {list.labelImage ? (
            <div
              className={style.image}
              style={{
                backgroundImage: `url(${list.labelImage.url})`
              }}
            />
          ) : (
            <span className={style.text}>{list.title}</span>
          )}
        </Link>
        <div className={style.description}>{list.description}</div>
        <Link to={viewAllPath}>
          <div className={style.viewAll}>
            View All
            <ArrowRightIcon />
          </div>
        </Link>
      </div>
      <div className={style.container}>
        <div
          className={style.list}
          style={{
            width: `${scenes.length * 164}px`
          }}
        >
          {scenes.map((scene) => {
            let { title } = scene
            if (title && title.length > 50) {
              title = `${title.substr(0, 50)}...`
            }
            return (
              <Link key={scene.id} to={`/scene/${scene.id}`}>
                <div className={style.card}>
                  <LazyLoad height='100%'>
                    <img
                      alt='thumbnail'
                      className={style.thumbnail}
                      src={scene.image.url}
                      onLoad={(e) => {
                        e.currentTarget.style.opacity = '1'
                      }}
                    />
                  </LazyLoad>
                  <div className={style.meta}>
                    <div className={style.title}>{title}</div>
                    <div className={style.accountIconContainer}>
                      <Link to={`/user/${scene.user.id}`}>
                        <AccountIcon
                          username={scene.user?.username || ''}
                          profileImageUrl={scene.user?.profileImageUrl || null}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}
