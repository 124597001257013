import X2JS from 'x2js'

/**
 * Retrieves poly licenses from XML.
 * @param xml Scene XML.
 * @return License texts
 */
export function getPolyLicensesFromXml (xml: string): string[] {
  const x2js = new X2JS()
  try {
    const json = x2js.xml2js(xml) as any
    const licenseMap = new Map<string, string>()
    let assets = json.stylyAssetDataSet.AssetDataSet.stylyAssetData as any[]
    if (!assets) {
      return []
    }
    if (!Array.isArray(assets)) {
      assets = [assets]
    }
    assets.forEach(assetData => {
      if (
        assetData.vals.string &&
        assetData.vals.string[0] &&
        assetData.vals.string[0] === 'mmh://poly/' &&
        assetData.vals.string[3]
      ) {
        const lines = assetData.vals.string[3].split('\n').slice(0, 3)
        const url = lines[2]
        licenseMap.set(url, lines.join('\n'))
      }
    })
    const licenses = [] as string[]
    licenseMap.forEach(value => licenses.push(value))
    return licenses
  } catch (e) {
    return []
  }
}
