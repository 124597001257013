import { v4 as uuid } from 'uuid'
import {
  SceneApi,
  TagApi,
  UserApi,
  SceneListItem as ApiSceneListItem,
  Scene as ApiScene
} from 'utils/api_client'
import SceneListItem from './models/SceneListItem'
import Scene from './models/Scene'
import User from './models/User'
import Tag from './models/Tag'
import axios from 'axios'
import { getPolyLicensesFromXml } from 'utils/scene_xml'

/**
 * This is the latest API client for this project.
 * Please add new apis here.
 */
export default class ApiClient {
  // Storing v1 path
  basePath: string
  sceneApiClient: SceneApi
  tagApiClient: TagApi
  userApiClient: UserApi

  /**
   * @param {string} apiBasePath Base path of API e.g. https://api.com/api/v1
   */
  constructor (apiBasePath: string) {
    this.basePath = apiBasePath
    const basePath = apiBasePath.replace('v1', 'v2')

    this.sceneApiClient = new SceneApi({ basePath: basePath })
    this.tagApiClient = new TagApi({ basePath: basePath })
    this.userApiClient = new UserApi({ basePath: basePath })
  }

  async getScene (id: string) {
    const res = await this.sceneApiClient.getScene(id)
    return convertScene(res.data)
  }

  /**
   * Searches scenes.
   */
  async searchScenes (query = '', limit = 100, offset = 0) {
    const res = await this.sceneApiClient.search(query, limit, offset)
    return {
      scenes: convertSceneList(res.data.scenes),
      total: res.data.total
    }
  }

  async getPublicScenesByIds (ids: Array<string>) {
    const res = await this.sceneApiClient.getPublicScenesByIds({ scene_ids: ids })
    return convertSceneList(res.data.scenes)
  }

  async getUserById (id: number) {
    const res = await this.userApiClient.getUser(String(id))
    const user = res.data
    return {
      id: user.id,
      username: user.username,
      profileImageUrl: user.profile_image_url
    } as User
  }

  async getScenesByUserId (id: number, limit = 100, offset = 0) {
    const res = await this.sceneApiClient.getScenesByUserId(id, limit, offset)
    return {
      scenes: convertSceneList(res.data.scenes),
      total: res.data.total
    }
  }

  async getSceneXml (id: string) {
    const res = await axios.get(`${this.basePath}/scene/${id}`)
    const xml = res.data.sc_xml
    if (xml) {
      return xml
    } else {
      return null
    }
  }

  async getSceneLicenses (id: string) {
    const xml = await this.getSceneXml(id)
    if (xml) {
      return getPolyLicensesFromXml(xml)
    } else {
      return null
    }
  }

  async getRecemmendedScenesBySceneId (id: string, limit = 6) {
    const scene = await this.getScene(id)
    let scenes = [] as SceneListItem[]
    const userScenes = await this.getScenesByUserId(scene.user.id, limit)
    if (userScenes.scenes.length < limit) {
      scenes = userScenes.scenes
      if (scene.tags.length > 0) {
        const searchScenes = await this.searchScenes(scene.tags[0].text, limit)
        scenes = scenes.concat(searchScenes.scenes.slice(0, limit - scenes.length))
      }
    } else {
      scenes = userScenes.scenes.splice(0, limit)
    }
    return scenes
  }
}

function convertScene (apiScene: ApiScene): Scene {
  let user: User | undefined
  if (apiScene.user === undefined) {
    user = {
      id: -1,
      username: 'anonymous',
      profileImageUrl: undefined
    }
  } else {
    user = {
      id: apiScene.user.id,
      username: apiScene.user.username,
      profileImageUrl: apiScene.user.profile_image_url
    }
  }

  return {
    id: apiScene.id,
    user: user,
    title: apiScene.title || '',
    description: apiScene.description || '',
    thumbnail: {
      id: uuid(),
      url: apiScene.thumbnail_middle_url,
      width: 540,
      height: 960
    },
    updatedAt: apiScene.updated_at,
    publishedAt: apiScene.published_at,
    gooded: apiScene.gooded || false,
    goodCount: apiScene.good_count || 0,
    mylisted: apiScene.mylisted || false,
    tags: apiScene.tags?.map(t => {
      return {
        id: t.id,
        text: t.text
      } as Tag
    }) ?? []
  }
}

function convertSceneList (apiScenes: ApiSceneListItem[]): SceneListItem[] {
  return apiScenes.map((scene): SceneListItem => {
    let user: User | undefined
    if (scene.user === undefined) {
      user = {
        id: -1,
        username: 'anonymous',
        profileImageUrl: undefined
      }
    } else {
      const profileImageUrl = scene.user.profile_image_url ? scene.user.profile_image_url + '?fm=jpg&q=0' : ''
      user = {
        id: scene.user.id,
        username: scene.user.username,
        profileImageUrl
      }
    }

    const thumbnailUrl = scene.thumbnail_middle_url ? scene.thumbnail_middle_url + '?fm=jpg&q=0' : ''

    return {
      id: scene.id,
      user: user,
      title: scene.title,
      thumbnail: {
        id: uuid(),
        url: thumbnailUrl,
        width: 540,
        height: 960
      },
      updatedAt: scene.updated_at,
      publishedAt: scene.published_at,
      gooded: scene.gooded,
      mylisted: scene.mylisted
    }
  })
}
