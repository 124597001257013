import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { REDUX_ACTION_TYPE_PREFIX } from 'app/constants'

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/App`

export const slice = createSlice({
  name: ACTION_TYPE_PREFIX,
  initialState: {
    markerSceneId: null as (string | null),
    markerSceneTitle: null as (string | null),
    markerSceneThumbnailUrl: null as (string | null)
  },
  reducers: {
    showMarker: (state, action: PayloadAction<{ id: string, title: string, thumbnailUrl: string}>) => {
      const { id, title, thumbnailUrl } = action.payload
      return {
        ...state,
        markerSceneId: id,
        markerSceneTitle: title,
        markerSceneThumbnailUrl: thumbnailUrl
      }
    },
    hideMarker: (state) => {
      return {
        ...state,
        markerSceneId: null,
        markerSceneThumbnailUrl: null
      }
    }
  }
})

export const selectState = (state: RootState) => state.app

export default slice.reducer
