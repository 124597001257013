
import qr from 'qrcode'
import markerBackgroundUrl from './marker_dark.png'

/**
 * Placeholder for sceneId embedded in mobile URL.
 * @type {string}
 */
const SCENE_ID_PLACEHOLDER = '@@SCENE_ID_PLACEHOLDER@@'
/**
 * URL to access a scene on mobile app.
 * @type {string}
 */
const MOBILE_ACCESS_URL_TEMPLATE = `https://stylymr.page.link/?link=https%3A%2F%2Fstyly.cc%2Fja%2Fmobile%2F%3Fscene%3D${SCENE_ID_PLACEHOLDER}&apn=com.psychicvrlab.stylymr&isi=1477168256&ibi=com.psychicvrlab.stylymr`
/**
 * Creates a URL for opening a scene on STYLY mobile app.
 * @param {string} sceneId UUID format scene ID.
 * @return {string} Generated URL.
 */
export function createMobileSceneUrl (sceneId: string): string {
  return MOBILE_ACCESS_URL_TEMPLATE.replace(SCENE_ID_PLACEHOLDER, sceneId)
}
/**
 * Width of background image.
 * @type {number}
 */
const BACKGROUND_WIDTH = 1280
/**
 * Height of background image.
 * @type {number}
 */
const BACKGROUND_HEIGHT = 720
/**
 * Marker width and height. Must be a square.
 * @type {number}
 */
const MARKER_SIZE = (720 * 4) / 5
/**
 * Size of QR code relative to Marker size.
 * @type {number}
 */
const QR_SIZE = (MARKER_SIZE * 2) / 5
/**
 * Creates a STYLY marker.
 * @param {string} backgroundUrl Background image URL
 * @param {string} qrUrl URL to convert to a QR
 * @return {string} Marker data URL
 */
export async function createMarker (backgroundUrl: string, qrUrl: string) {
  const canvas = document.createElement('canvas')
  canvas.width = 1280
  canvas.height = 720
  const context = canvas.getContext('2d')
  if (!context) {
    throw new Error('Could not get canvas context.')
  }
  const qrImageUrl = await qr.toDataURL(qrUrl, { errorCorrectionLevel: 'L' })
  const imgUrls = [backgroundUrl, markerBackgroundUrl, qrImageUrl]
  const loadImage = (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = () => resolve(img)
      img.onerror = () => reject(new Error('Failed to load an image.'))
      img.src = url
    })
  }
  const imgs = await Promise.all(imgUrls.map(loadImage)) as CanvasImageSource[]
  context.drawImage(imgs[0], 0, 0, BACKGROUND_WIDTH, BACKGROUND_HEIGHT)
  context.drawImage(
    imgs[1],
    BACKGROUND_WIDTH - MARKER_SIZE,
    BACKGROUND_HEIGHT - MARKER_SIZE,
    MARKER_SIZE,
    MARKER_SIZE
  )
  context.drawImage(
    imgs[2],
    BACKGROUND_WIDTH - (MARKER_SIZE + QR_SIZE) / 2,
    BACKGROUND_HEIGHT - (MARKER_SIZE + QR_SIZE) / 2,
    QR_SIZE,
    QR_SIZE
  )
  return canvas.toDataURL('image/png', 1.0)
}
