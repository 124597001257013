import React from 'react'
import style from '../style.module.scss'

type Props = {
  onClick?: () => void
}

export default React.memo(function Qr ({ onClick }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={style.svg}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      onClick={onClick}
    >
      <g fillRule="evenodd">
        <path d="M24.5,14 L21,14 L21,7 L24.5,7 L24.5,10.5 L31.5,10.5 L31.5,14 L28,14 L28,17.5 L24.5,17.5 L24.5,14 Z M31.5,56 L31.5,52.5 L28,52.5 L28,56 L31.5,56 Z M28,3.5 L28,0 L21,0 L21,3.5 L24.5,3.5 L24.5,7 L28,7 L28,3.5 Z M56,0 L56,21 L52.5,21 L52.5,17.5 L38.5,17.5 L38.5,0 L56,0 Z M52.5,14 L52.5,3.5 L42,3.5 L42,14 L52.5,14 Z M3.5,21 L0,21 L0,24.5 L7,24.5 L7,21 L3.5,21 Z M3.5,31.5 L7,31.5 L7,35 L10.5,35 L10.5,24.5 L7,24.5 L7,28 L0,28 L0,35 L3.5,35 L3.5,31.5 Z M21,35 L21,42 L24.5,42 L24.5,35 L21,35 Z M35,0 L31.5,0 L31.5,10.5 L35,10.5 L35,0 Z M35,17.5 L35,14 L31.5,14 L31.5,17.5 L35,17.5 Z M45.5,10.5 L49,10.5 L49,7 L45.5,7 L45.5,10.5 Z M10.5,7 L7,7 L7,10.5 L10.5,10.5 L10.5,7 Z M17.5,0 L17.5,17.5 L0,17.5 L0,0 L17.5,0 Z M14,3.5 L3.5,3.5 L3.5,14 L14,14 L14,3.5 Z M45.5,21 L45.5,24.5 L42,24.5 L42,21 L35,21 L35,24.5 L31.5,24.5 L31.5,21 L21,21 L21,17.5 L17.5,17.5 L17.5,21 L14,21 L14,24.5 L17.5,24.5 L17.5,31.5 L21,31.5 L21,28 L24.5,28 L24.5,35 L28,35 L28,28 L38.5,28 L38.5,31.5 L31.5,31.5 L31.5,35 L38.5,35 L38.5,38.5 L42,38.5 L42,28 L45.5,28 L45.5,31.5 L49,31.5 L49,38.5 L52.5,38.5 L52.5,35 L56,35 L56,31.5 L52.5,31.5 L52.5,28 L49,28 L49,24.5 L52.5,24.5 L52.5,21 L45.5,21 Z M7,49 L10.5,49 L10.5,45.5 L7,45.5 L7,49 Z M0,38.5 L17.5,38.5 L17.5,56 L0,56 L0,38.5 Z M3.5,52.5 L14,52.5 L14,42 L3.5,42 L3.5,52.5 Z M45.5,42 L45.5,38.5 L42,38.5 L42,42 L45.5,42 Z M45.5,45.5 L42,45.5 L42,49 L38.5,49 L38.5,45.5 L35,45.5 L35,42 L38.5,42 L38.5,38.5 L31.5,38.5 L31.5,35 L28,35 L28,42 L24.5,42 L24.5,45.5 L21,45.5 L21,56 L24.5,56 L24.5,52.5 L28,52.5 L28,49 L35,49 L35,56 L38.5,56 L38.5,52.5 L42,52.5 L42,56 L49,56 L49,52.5 L52.5,52.5 L52.5,49 L45.5,49 L45.5,45.5 Z M14,35 L17.5,35 L17.5,31.5 L14,31.5 L14,35 Z M49,42 L49,45.5 L52.5,45.5 L52.5,49 L56,49 L56,38.5 L52.5,38.5 L52.5,42 L49,42 Z M56,56 L56,52.5 L52.5,52.5 L52.5,56 L56,56 Z M56,28 L56,24.5 L52.5,24.5 L52.5,28 L56,28 Z" transform="translate(12 12)"/>
      </g>
    </svg>
  )
})
