import { createClient, ContentfulClientApi, Asset } from 'contentful'
import Image from 'models/Image'
import PickupSceneList from './PickupSceneList'
import { TopAd, TopAdEntryFields } from './TopAd'
import { FeaturedTag, FeaturedTagEntryFields } from './FeaturedTag'
import { Locale } from 'utils/locale'

/** 対象プラットフォーム */
const targetPlatform: string = 'mobile-app'

/**
 * Parses Contentful media object to Image.
 */
function parseAssetToImage (media: Asset): Image {
  return {
    id: media.sys.id,
    url: `${media.fields.file.url.replace(
      /^\/\//,
      'https://'
    )}?fm=jpg&fl=progressive`,
    width: media.fields.file.details.image?.width ?? 0,
    height: media.fields.file.details.image?.height ?? 0
  }
}

/**
 */
function parseResultToPickupSceneLists (result: any): PickupSceneList[] {
  const { items, includes } = result
  const lists = items.map((entry: any) => {
    const {
      priority,
      title,
      description,
      searchQuery,
      labelImage,
      scenes
    } = entry.fields
    return {
      id: entry.sys.id,
      priority,
      title,
      description: description || '',
      searchQuery,
      labelImage: labelImage ? parseAssetToImage(labelImage) : null,
      scenes: scenes
        ? scenes
          .map((sceneLink: any) => {
            try {
              const entry = includes.Entry.find((entry: any) => {
                return entry.sys.id === sceneLink.sys.id
              })
              const asset = includes.Asset.find((asset: Asset) => {
                return asset.sys.id === entry.fields.media.sys.id
              })
              return {
                cmsId: entry.sys.id,
                sceneId: entry.fields.sceneId,
                title: entry.fields.title,
                description: entry.fields.description,
                image: asset ? parseAssetToImage(asset) : null,
                articleUrl: entry.fields.articleUrl
              }
            } catch (e) {
              return null
            }
          })
          .filter((s: any) => s !== null)
        : []
    }
  })
  return lists
}

/**
 * API client wrapper for Contentful.
 */
export class ContentfulClient {
  /**
   * Contentful API client instance.
   */
  client: (ContentfulClientApi | null) = null

  locale = Locale.ENGLISH

  /**
   * @param {string} space Space ID
   * @param {string} env Environment name
   * @param {string} accessToken Access token for the env and the space.
   */
  constructor (space: string, env: string, accessToken: string) {
    this.client = createClient({
      space,
      environment: env,
      accessToken
    })
  }

  /**
   * @param {string} locale
   */
  setLocale (locale: Locale) {
    this.locale = locale
  }

  /**
   * @param query Query string to get list by.
   */
  async getPickupSceneListBySearchQuery (query: string) {
    if (this.client === null) {
      throw new Error('Client is null')
    }
    const result = await this.client.getEntries({
      content_type: 'pickupSceneList',
      include: 2,
      'fields.searchQuery': query,
      'fields.targetPlatform': targetPlatform,
      locale: this.locale
    })
    const lists = parseResultToPickupSceneLists(result)
    if (lists.length > 0) {
      return lists[0]
    }
    return null
  }

  async getPickupSceneLists () {
    if (this.client === null) {
      throw new Error('Client is null')
    }
    const result = await this.client.getEntries({
      content_type: 'pickupSceneList',
      include: 2,
      order: 'fields.priority',
      'fields.targetPlatform': targetPlatform,
      locale: this.locale
    })
    const lists = parseResultToPickupSceneLists(result)
    return lists
  }

  async getStaffPicks () {
    if (this.client === null) {
      throw new Error('Client is null')
    }
    const entries = await this.client.getEntries({
      content_type: 'pickupScene',
      'fields.showInStaffPicks': true,
      'fields.targetPlatform': targetPlatform,
      locale: this.locale
    })
    const scenes = entries.items.map((entry: any) => {
      return {
        cmsId: entry.sys.id,
        sceneId: entry.fields.sceneId,
        tilte: entry.fields.title,
        description: entry.fields.description,
        image: entry.fields.media ? parseAssetToImage(entry.fields.media) : null,
        articleUrl: entry.fields.articleUrl
      }
    })
    return scenes
  }

  async getFeaturedTags () {
    if (this.client === null) {
      throw new Error('Client is null')
    }
    const entries = await this.client.getEntries<FeaturedTagEntryFields>({
      content_type: 'featuredTag',
      'fields.targetPlatform': targetPlatform
    })
    const tags = entries.items.map(entry => {
      return {
        id: entry.sys.id,
        text: entry.fields.tag,
        important: entry.fields.important,
        priority: entry.fields.priority
      } as FeaturedTag
    })
    return tags
  }

  async getTopAds () {
    if (this.client === null) {
      throw new Error('Client is null')
    }
    const entries = await this.client.getEntries<TopAdEntryFields>({
      content_type: 'galleryTopAd',
      'fields.targetPlatform': targetPlatform,
      locale: this.locale
    })
    const ads = entries.items.map(entry => {
      const { destinationUrl, image } = entry.fields
      return {
        id: entry.sys.id,
        image: image ? parseAssetToImage(image) : null,
        destinationUrl
      } as TopAd
    })
    return ads
  }
}
