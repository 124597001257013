import { createAsyncThunk, EnhancedStore, MiddlewareArray } from '@reduxjs/toolkit'
import { REDUX_ACTION_TYPE_PREFIX } from 'app/constants'
import history from 'utils/history'
import { Tunnel } from 'utils/tunnel'
import { AnyAction } from 'redux'
import { ThunkMiddleware } from 'redux-thunk'
import isUUID from 'uuid-validate'

/**
 * Please use this prefix for actinos in this file.
 */
const ACTION_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/tunnel`

/**
 * Single tunnel instance throughout this project.
 */
let tunnel: Tunnel

/**
 * Setup tunnel instance. Please call this method once in this project.
 * @param store Redux store
 */
export function configureTunnel (store: EnhancedStore<any, AnyAction, MiddlewareArray<ThunkMiddleware>>) {
  if (tunnel) {
    return
  }
  tunnel = new Tunnel((req) => {
    if (req.context === 'ECHO') {
      if (!req.payload || !req.payload.message || (typeof req.payload.message) !== 'string') {
        req.respondError('No message. Please send message.')
      } else {
        req.respondOk({ message: req.payload.message })
      }
    }
    if (req.context === 'OPEN_SCENE_PAGE') {
      if (!req.payload ||
        !req.payload.id ||
        (typeof req.payload.id) !== 'string' ||
        !isUUID(req.payload.id)
      ) {
        req.respondError('No ID. Please send ID.')
      } else {
        store.dispatch(openScenePage(req.payload.id))
        req.respondOk({ message: 'Opened scene page' })
      }
    }
  })
  tunnel.open()
}

/**
 * App initialized.
 */
export const notifyAppInitialized = createAsyncThunk(
  `${ACTION_PREFIX}/notifyAppInitialized`,
  () => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('APP_INITIALIZED', {}, () => {
        resolve()
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)

/**
 * Opens a scene page.
 */
export const openScenePage = createAsyncThunk(
  `${ACTION_PREFIX}/openScenePage`,
  (id: string) => {
    history.push(`/scene/${id}`)
  }
)

/**
 * Notifies that user logged in.
 */
export const notifyLogin = createAsyncThunk(
  `${ACTION_PREFIX}/notifyLogin`,
  () => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('LOGIN', {}, () => {
        resolve()
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)

/**
 * Notifies that user logged out.
 */
export const notifyLogout = createAsyncThunk(
  `${ACTION_PREFIX}/notifyLogout`,
  () => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('LOGOUT', {}, () => {
        resolve()
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)

/**
 * Notifies that authentication has been expired.
 */
export const notifyAuthExpired = createAsyncThunk(
  `${ACTION_PREFIX}/notifyAuthExpired`,
  () => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('AUTH_EXPIRED', {}, () => {
        resolve()
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)

/**
 * Sends a request to open a scene.
 */
export const playScene = createAsyncThunk(
  `${ACTION_PREFIX}/playScene`,
  (sceneId: string) => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('PLAY_SCENE', {
        id: sceneId
      }, () => {
        resolve()
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)

/**
 * Sends a request to share a scene.
 */
export const shareScene = createAsyncThunk(
  `${ACTION_PREFIX}/shareScene`,
  ({ id, title }: { id: string, title: string }) => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('SHARE_SCENE', {
        id,
        title,
        url: `https://gallery.styly.cc/scene/${id}`
      }, () => {
        resolve()
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)

/**
 * Sends a request to open camera for reading QR.
 */
export const openCamera = createAsyncThunk(
  `${ACTION_PREFIX}/openCamera`,
  () => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('OPEN_CAMERA', {}, () => {
        resolve()
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)

/**
 * Requests opening a extenral web page.
 */
export const openExternalPage = createAsyncThunk(
  `${ACTION_PREFIX}/openExternalPage`,
  (url: string) => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('OPEN_EXTERNAL_PAGE', { url }, () => {
        resolve()
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)

/**
 * Sends a ECHO debug message.
 */
export const echo = createAsyncThunk(
  `${ACTION_PREFIX}/echo`,
  (message: string) => {
    return new Promise((resolve, reject) => {
      tunnel.sendRequest('ECHO', {
        message
      }, (payload: any) => {
        if (payload.message) {
          resolve(payload.message)
        } else {
          reject(new Error('No message ECHO'))
        }
      }, (message) => {
        reject(new Error(message))
      })
    })
  }
)
