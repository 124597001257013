import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import ApiClient from 'utils/api'
import { RootState, AppDispatch } from 'app/store'
import { REDUX_ACTION_TYPE_PREFIX } from 'app/constants'
import {
  API_BASE_PATH,
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_ENV,
  CONTENTFUL_SPACE
} from 'app/env'
import { ContentfulClient } from 'utils/contentful'
import ContentfulPickupSceneList from 'utils/contentful/PickupSceneList'
import PickupScene from 'models/PickupScene'
import { TopAd } from 'utils/contentful/TopAd'
import { FeaturedTag } from 'utils/contentful/FeaturedTag'
import { getLocale } from 'utils/locale'

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/TopPage`

interface SceneListMap {
  [key: string]: PickupScene[]
}

export const initialize = createAsyncThunk<{
  lists: ContentfulPickupSceneList[]
  ads: TopAd[]
  tags: FeaturedTag[]
  staffPicksScenes: PickupScene[]
}, void, {
  dispatch: AppDispatch
}>(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async (_, thunk) => {
    const contentfulClient = new ContentfulClient(
      CONTENTFUL_SPACE,
      CONTENTFUL_ENV,
      CONTENTFUL_ACCESS_TOKEN
    )
    contentfulClient.setLocale(getLocale())
    const apiClient = new ApiClient(API_BASE_PATH)
    const lists = await contentfulClient.getPickupSceneLists()
    const pickupIds = [] as string[]
    for (let i = 0; i < lists.length; i++) {
      const list = lists[i]
      list.scenes.forEach(s => {
        pickupIds.push(s.sceneId)
      })
    }
    const pickupScenes = await apiClient.getPublicScenesByIds(pickupIds)
    for (let i = 0; i < lists.length; i++) {
      const list = lists[i]
      const ids = list.scenes.map(s => {
        return s.sceneId
      })
      const scenes = pickupScenes.filter(scene => {
        return ids.includes(scene.id)
      })
      const convertedScenes = scenes.map(s => {
        return {
          id: s.id,
          user: s.user,
          title: s.title,
          description: '',
          image: s.thumbnail,
          gooded: s.gooded,
          mylisted: s.mylisted,
          articleUrl: ''
        } as PickupScene
      })
      thunk.dispatch(addSceneList({
        listId: list.id,
        scenes: convertedScenes
      }))
    }
    const ads = await contentfulClient.getTopAds()
    const tags = await contentfulClient.getFeaturedTags()

    const staffPicks = await contentfulClient.getStaffPicks()
    const ids = staffPicks.map(s => {
      return s.sceneId
    })
    const apiScenes = await apiClient.getPublicScenesByIds(ids)
    const convertedScenes = staffPicks
      .map(pickupScene => {
        const scene = apiScenes.find(scene => {
          return scene.id === pickupScene.sceneId
        })
        if (!scene) {
          return null
        }
        return {
          id: scene.id,
          user: scene.user,
          title: scene.title,
          description: pickupScene.description,
          image: pickupScene.image || scene.thumbnail,
          articleUrl: pickupScene.articleUrl || '',
          gooded: scene.gooded,
          mylisted: scene.mylisted
        } as PickupScene
      })
      .filter(s => s !== null) as PickupScene[]

    thunk.dispatch(fetchLatest())

    return {
      lists,
      ads,
      tags: tags.sort((a, b) => {
        return a.text < b.text ? -1 : 1
      }).sort((a, b) => {
        return a.important > b.important ? -1 : 1
      }),
      staffPicksScenes: convertedScenes
    }
  }
)

export const fetchLatest = createAsyncThunk<void, void, {
  dispatch: AppDispatch
}>(
  `${ACTION_TYPE_PREFIX}/fetchLatest`,
  async (_, thunk) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    const result = await apiClient.searchScenes('', 30, 0)
    const convertedScenes = result.scenes
      .map(scene => {
        return {
          id: scene.id,
          user: scene.user,
          title: scene.title,
          description: '',
          image: scene.thumbnail,
          articleUrl: '',
          gooded: scene.gooded,
          mylisted: scene.mylisted
        } as PickupScene
      })
    thunk.dispatch(addSceneList({
      listId: 'latest',
      scenes: convertedScenes
    }))
  }
)

export const slice = createSlice({
  name: `${REDUX_ACTION_TYPE_PREFIX}/scenePage`,
  initialState: {
    initialized: false,
    initializing: false,
    pickupSceneLists: [] as ContentfulPickupSceneList[],
    pickupSceneListMap: {} as SceneListMap,
    ads: [] as TopAd[],
    featuredTags: [] as FeaturedTag[],
    staffPicksScenes: [] as PickupScene[]
  },
  reducers: {
    addSceneList: (state, action: PayloadAction<{ listId: string, scenes: PickupScene[]}>) => {
      const { listId, scenes } = action.payload
      const { pickupSceneListMap } = state
      pickupSceneListMap[listId] = scenes
      state.pickupSceneListMap = { ...pickupSceneListMap }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        state.initializing = true
      })
      .addCase(initialize.fulfilled, (state, action) => {
        const { lists, ads, tags, staffPicksScenes } = action.payload
        state.initializing = false
        state.initialized = true
        state.pickupSceneLists = lists
        state.ads = ads
        state.featuredTags = tags
        state.staffPicksScenes = staffPicksScenes
      })
      .addCase(fetchLatest.fulfilled, (state) => {
        const latestList = {
          id: 'latest',
          priority: 100,
          title: 'Latest',
          description: '',
          searchQuery: '',
          labelImage: null,
          scenes: []
        } as ContentfulPickupSceneList
        state.pickupSceneLists = [...state.pickupSceneLists, latestList]
        return state
      })
  }
})

const { addSceneList } = slice.actions

export const selectState = (state: RootState) => state.topPage

export default slice.reducer
