import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ApiClient from 'utils/api'
import Scene from 'utils/api/models/Scene'
import SceneListItem from 'utils/api/models/SceneListItem'
import { RootState } from 'app/store'
import { FEATURED_TAGS, REDUX_ACTION_TYPE_PREFIX } from 'app/constants'
import {
  API_BASE_PATH
} from 'app/env'

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/scenePage`

export const loadScene = createAsyncThunk<Scene, string, {}>(
  `${ACTION_TYPE_PREFIX}/loadScene`,
  async (id: string, thunkAPI) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    thunkAPI.dispatch(loadLiceses(id))
    thunkAPI.dispatch(loadRecommendedScenes(id))
    const scene = await apiClient.getScene(id)
    scene.tags = scene.tags.sort((a, b) => {
      return a.text > b.text ? 1 : -1
    }).sort((a, b) => {
      return Number(FEATURED_TAGS.includes(b.text)) - Number(FEATURED_TAGS.includes(a.text))
    })
    return scene
  }
)

export const loadLiceses = createAsyncThunk<string[] | null, string, {}>(
  `${ACTION_TYPE_PREFIX}/loadLiceses`,
  async (id: string) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    return await apiClient.getSceneLicenses(id)
  }
)

export const loadRecommendedScenes = createAsyncThunk<SceneListItem[], string, {}>(
  `${ACTION_TYPE_PREFIX}/loadRecommendedScenes`,
  async (id: string) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    return await apiClient.getRecemmendedScenesBySceneId(id)
  }
)

export const slice = createSlice({
  name: `${REDUX_ACTION_TYPE_PREFIX}/scenePage`,
  initialState: {
    scene: null as (null| Scene),
    licenses: [] as string[],
    recommendedScenes: [] as SceneListItem[]
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadScene.pending, (state) => {
        return {
          ...state,
          scene: null
        }
      })
      .addCase(loadScene.fulfilled, (state, action) => {
        return {
          ...state,
          scene: action.payload
        }
      })
      .addCase(loadLiceses.fulfilled, (state, action) => {
        const licenses = action.payload
        return {
          ...state,
          licenses: licenses || []
        }
      })
      .addCase(loadRecommendedScenes.fulfilled, (state, action) => {
        return {
          ...state,
          recommendedScenes: action.payload
        }
      })
  }
})

export const selectState = (state: RootState) => state.scenePage

export default slice.reducer
