import React, { useState } from 'react'
import classnames from 'classnames'
import style from './style.module.scss'
import PlayIcon from '@material-ui/icons/PlayArrow'

type Props = {
  thumbnailUrl?: string,
  onClick: () => void
}

export default function Billboard ({ thumbnailUrl, onClick }:Props) {
  const [imgLoaded, setImgLoaded] = useState(false)
  return (
    <div className={style.frame} onClick={onClick}>
      <div className={style.thumbnailContainer}>
        <img
          alt="scene"
          className={classnames({
            [style.thumbnail]: true,
            [style.visible]: imgLoaded
          })}
          src={thumbnailUrl}
          onError={e => {
            e.currentTarget.style.display = 'none'
          }}
          onLoad={() => setImgLoaded(true)}
        />
        <div className={style.cover} />
      </div>
      <PlayIcon className={style.playIcon} />
    </div>
  )
}
