import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ApiClient from 'utils/api'
import User from 'utils/api/models/User'
import SceneListItem from 'utils/api/models/SceneListItem'
import { RootState } from 'app/store'
import { REDUX_ACTION_TYPE_PREFIX } from 'app/constants'
import { API_BASE_PATH } from 'app/env'

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/UserPage`

const client = new ApiClient(API_BASE_PATH)

const NUM_SCENES_OF_CHUNK = 48

export const initialize = createAsyncThunk<{
  user: User,
  scenes: SceneListItem[],
  total: number
}, number, {}>(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async (id: number) => {
    const user = await client.getUserById(id)
    const { scenes, total } = await client.getScenesByUserId(id, NUM_SCENES_OF_CHUNK, 0)
    return {
      user,
      scenes,
      total
    }
  }
)

export const fetchMoreScenes = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/fetchMoreScenes`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { scenes, user } = state.userPage
    const offset = scenes.length
    if (!user) {
      throw new Error('No user')
    }
    const res = await client.getScenesByUserId(user.id, NUM_SCENES_OF_CHUNK, offset)
    return res
  }
)

export const slice = createSlice({
  name: `${ACTION_TYPE_PREFIX}/scenePage`,
  initialState: {
    user: null as (User | null),
    initialized: false,
    initializing: false,
    fetchingScenes: false,
    scenes: [] as SceneListItem[],
    total: 0
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        return {
          ...state,
          initialized: false,
          initializing: true,
          fetchingScenes: true,
          scenes: [],
          total: 0
        }
      })
      .addCase(initialize.rejected, (state) => {
        return {
          ...state,
          initializing: false,
          fetchingScenes: false
        }
      })
      .addCase(initialize.fulfilled, (state, action) => {
        const {
          scenes,
          user,
          total
        } = action.payload
        return {
          ...state,
          initialized: true,
          initializing: false,
          fetchingScenes: false,
          scenes,
          user,
          total
        }
      })
      .addCase(fetchMoreScenes.pending, (state) => {
        return {
          ...state,
          fetchingScenes: true
        }
      })
      .addCase(fetchMoreScenes.rejected, (state) => {
        return {
          ...state,
          fetchScenes: false
        }
      })
      .addCase(fetchMoreScenes.fulfilled, (state, action) => {
        const {
          scenes: newScenes
        } = action.payload
        const scenes = [...state.scenes, ...newScenes]
        return {
          ...state,
          fetchingScenes: false,
          scenes
        }
      })
  }
})

export const selectState = (state: RootState) => state.userPage

export default slice.reducer
