import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import Root from './Root'
import 'sanitize.css/sanitize.css'

ReactDOM.render(
  <Root />,
  document.getElementById('root')
)
