import React, { useEffect } from 'react'
import { slice, selectState } from './redux'
import { ConnectedProps, connect, useSelector } from 'react-redux'
import MarkerPopup from 'features/MarkerPopup'
import TopPage from 'pages/TopPage'
import SceneSearchPage from 'pages/SceneSearchPage'
import ScenePage from 'pages/ScenePage'
import StaffPicksPage from 'pages/StaffPicksPage'
import UserPage from 'pages/UserPage'
import Footer from 'features/Footer'
import Header from 'features/Header'
import { Route, Switch } from 'react-router-dom'
import { openCamera, shareScene, playScene, notifyAppInitialized } from 'utils/tunnel/redux'
import style from './style.module.scss'

const mapDispatch = { openCamera, shareScene, playScene, notifyAppInitialized, ...slice.actions }
const connector = connect(null, mapDispatch)

type Props = ConnectedProps<typeof connector>

function App ({ openCamera, hideMarker, shareScene, notifyAppInitialized }: Props) {
  const { markerSceneId, markerSceneTitle, markerSceneThumbnailUrl } = useSelector(selectState)
  const hideMarkerInternal = () => {
    hideMarker()
  }
  const onShareOnMarker = () => {
    if (markerSceneId && markerSceneTitle) {
      shareScene({ id: markerSceneId, title: markerSceneTitle })
    }
  }

  useEffect(() => {
    notifyAppInitialized()
  }, [notifyAppInitialized])

  return (
    <div className={style.frame}>
      <Header />
      <Switch>
        <Route path="/search" component={SceneSearchPage} />
        <Route path="/scene/:id" component={ScenePage} />
        <Route path="/staff_picks" component={StaffPicksPage} />
        <Route path="/user/:id" component={UserPage} />
        <Route path="/" component={TopPage} />
      </Switch>
      <Footer onCameraClick={openCamera}/>
      <MarkerPopup
        open={!!markerSceneId}
        onClose={hideMarkerInternal}
        sceneId={markerSceneId || ''}
        onShare={onShareOnMarker}
        thumbnailUrl={markerSceneThumbnailUrl || ''}
      />
    </div>
  )
}

export default connector(App)
