import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ApiClient from 'utils/api'
import { ContentfulClient } from 'utils/contentful'
import PickupScene from 'models/PickupScene'
import { RootState } from 'app/store'
import { REDUX_ACTION_TYPE_PREFIX } from 'app/constants'
import {
  API_BASE_PATH,
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_ENV,
  CONTENTFUL_SPACE
} from 'app/env'

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/StaffPicksPage`

export const initialize = createAsyncThunk<PickupScene[], void, {}>(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async (_) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    const client = new ContentfulClient(
      CONTENTFUL_SPACE,
      CONTENTFUL_ENV,
      CONTENTFUL_ACCESS_TOKEN
    )
    const staffPicks = await client.getStaffPicks()
    const ids = staffPicks.map(s => {
      return s.sceneId
    })
    const apiScenes = await apiClient.getPublicScenesByIds(ids)
    const convertedScenes = staffPicks
      .map(pickupScene => {
        const scene = apiScenes.find(scene => {
          return scene.id === pickupScene.sceneId
        })
        if (!scene) {
          return null
        }
        return {
          id: scene.id,
          user: scene.user,
          title: scene.title,
          description: pickupScene.description,
          image: pickupScene.image || scene.thumbnail,
          articleUrl: pickupScene.articleUrl || '',
          gooded: scene.gooded,
          mylisted: scene.mylisted
        } as PickupScene
      })
      .filter(s => s !== null) as PickupScene[]
    return convertedScenes
  }
)

export const slice = createSlice({
  name: `${REDUX_ACTION_TYPE_PREFIX}/scenePage`,
  initialState: {
    initializing: false,
    scenes: [] as PickupScene[]
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        return {
          ...state,
          initializing: true
        }
      })
      .addCase(initialize.fulfilled, (state, action) => {
        return {
          ...state,
          initializing: false,
          scenes: action.payload
        }
      })
  }
})

export const selectState = (state: RootState) => state.staffPicksPage

export default slice.reducer
