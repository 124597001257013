/**
 * Redux action type prefix for this project.
 * Please use this prefix for all actions.
 * e.g. STYLY_MOBILE/sampleAction
 */
export const REDUX_ACTION_TYPE_PREFIX = 'STYLY_MOBILE'

/**
 * Featrued tag
 */
export const FEATURED_TAGS = [
  'AR',
  'Art',
  'Architecture',
  'Entertainment',
  'Fashion',
  'Music',
  'VR'
]
