import React, { useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import hljs from 'utils/highlight'
import 'highlight.js/styles/monokai-sublime.css'
import style from './style.module.scss'

type CodeBlockProps = {
  language: string
  value: string
}

function CodeBlock ({ language, value }: CodeBlockProps) {
  const codeElRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (codeElRef.current) {
      hljs.highlightBlock(codeElRef.current)
    }
  })

  return (
    <pre>
      <code ref={codeElRef} className={`language-${language}`}>
        {value}
      </code>
    </pre>
  )
}

type MarkdownProps = {
  source: string,
  onClickLink?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export default function Markdown ({ source, onClickLink }: MarkdownProps) {
  const onClickLinkInternal = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement
    if (target.tagName === 'A') {
      e.preventDefault()
      onClickLink && onClickLink(e as React.MouseEvent<HTMLAnchorElement>)
    }
  }
  return (
    <div className={style.wrapper} onClickCapture={onClickLinkInternal}>
      <ReactMarkdown
        source={source}
        renderers={{
          code: CodeBlock
        }}
      />
    </div>
  )
}
